<template>
  <section class="hero is-fullheight payment-page">
    <PmNavbar />
    <div class="hero-body light-bg">
      <div class="container">
        <div class="columns is-multiline mt-5 white-bg">
          <div class="column is-12">
            <div class="columns">
              <div class="column is-12">
                <h3 class="has-text-centered custom-black cstm-font-color cstm-underline font-secondary">
                  {{ this.$t("message.pages.paymentPage.title") }}
                </h3>
                <p class="has-text-centered font-18 custom-black mt-5">
                  {{ this.$t("message.pages.paymentPage.subtitle") }}
                </p>
              </div>
            </div>
          </div>

          <!-- Start Cart Line -->
          <div
            v-for="(product, index) in not_draft_products"
            :key="index"
            class="column is-10 is-offset-1 border-bottom px-5"
          >
            <div class="columns">
              <div class="column is-4 is-flex is-align-items-center is-flex-direction-column">
                <figure class="image is-128x128 is-flex">
                  <img
                    v-if="product.thumbnail_url != ''"
                    :src="product.thumbnail_url"
                    class="m-auto"
                  />
                  <img
                    v-else-if="product.product.thumbnail != ''"
                    :src="product.product.thumbnail"
                    class="m-auto"
                    style="height: 100px; width: auto;"
                  />
                  <img v-else src="../assets/img/cup_6oz.png" class="m-auto" />
                </figure>

                <p class="font-18">{{getProductName(product.product.id)}}</p>
              </div>

              <div
                class="column is-2 is-flex is-align-items-center is-justify-content-center is-relative"
              >
                <div class="text-wrapper is-inline-block">
                  <p>x {{ product.amount }}</p>
                </div>
              </div>

              <div
                class="column is-2 is-flex is-align-items-center"
              >
                <span class="font-18"
                  >{{ this.$t("message.pages.paymentPage.eachPrepText") }}
                  {{ getVolumeDiscountPerCupSize(product.product.id) }} € </span
                >

              </div>

            </div>
          </div>

          <div
            v-for="(form, index) in non_draft_forms"
            :key="index"
            class="column is-10 is-offset-1 border-bottom px-5"
          >
            <div class="columns">
              <div class="column is-4 is-flex is-align-items-center is-flex-direction-column">
                <figure class="image is-128x128 is-flex">
                  <img
                    v-if="form.bg_url == ''"
                    src="../assets/img/cup_6oz.png"
                    class="m-auto"
                  />
                  <img v-else :src="form.bg_url" class="m-auto" />
                </figure>
                <p class="font-18">{{getProductName(form.product)}}</p>
              </div>

              <div
                class="column is-2 is-flex is-align-items-center is-justify-content-center is-relative"
              >
                <div class="text-wrapper is-inline-block">
                  <p>x {{ form.amount }}</p>
                </div>
              </div>

              <div
                class="column is-2 is-flex is-align-items-center"
              >
                <span class="font-18"
                  >{{ this.$t("message.pages.paymentPage.eachPrepText") }}
                  {{ getVolumeDiscountPerCupSize(form.product) }} €</span
                >
              </div>
            </div>
          </div>          
        
          <!-- End Cart Line -->

          <!-- Start amount by cup sizes -->
          <div class="column is-10 is-offset-1">
            <div class="columns">
              <div class="column has-text-centered">
                <template v-for="(cup, idx) in this.$store.getters.getProducts" :key="idx">
                  <p class="font-18 has-text-weight-bold" v-if="this.$store.getters.getItemsTotalAmountByProduct(cup.id) > 0">{{cup.name}}: {{ this.$store.getters.getItemsTotalAmountByProduct(cup.id) }} {{ this.$t("message.pages.cartPage.eachPieceText") }} {{ this.$t("message.pages.paymentPage.eachPrepText") }} {{ getVolumeDiscountPerCupSize(cup.id) }} € = {{(getVolumeDiscountPerCupSize(cup.id) * this.$store.getters.getItemsTotalAmountByProduct(cup.id)).toFixed(2)}} €</p>
                </template>
              </div>
            </div>
          </div>
          <!-- End amount by cup sizes -->    

          <!-- Start total amount -->
            <div class="column is-10 is-offset-1">
              <div class="columns">
                <div class="column has-text-centered">
                  <p class="has-text-weight-bold font-18" v-if="non_draft_forms.length > 0">{{ this.$t("message.pages.paymentPage.suunniText") }} {{non_draft_forms.length}} x {{this.$store.getters.getDesignServiceFee.price}} € = {{getDesignServiceTotalFee}} €</p>
                  
                  <span class="has-text-weight-bold font-18">{{ this.$store.getters.getItemsTotalAmount }} {{ this.$t("message.pages.paymentPage.eachPieceText") }}</span>
                  <span class="font-18 has-text-weight-bold"> = {{ getTotalPrice }} €</span>
                </div>
              </div>
            </div>                 
          <!-- End total amount -->

          <!-- Start total amount with VAT-->
            <div class="column is-10 is-offset-1">
              <div class="columns">
                <div class="column has-text-centered">
                  <span class="has-text-weight-bold font-18">ALV 25.5%</span>
                  <span class="font-18 has-text-weight-bold"> = {{ ((getTotalPrice * 1.255) + getDesignServiceTotalFee).toFixed(2) }} €</span>
                </div>                  
              </div>
            </div>
          <!-- End total amount with VAT-->
          <div
            class="column is-10 is-offset-1"
            v-if="this.voucherDiscount && this.voucherDiscount > 0"
          >
            <div class="columns">
              <div
                class="column is-2 is-offset-4 is-flex is-align-items-center is-justify-content-center"
              >
                <i class="fas fa-plus-circle mr-3 visibility-hidden"></i>
                <span class="has-text-weight-bold font-18"
                  >- {{ this.voucherDiscount }} €</span
                >
                <i class="fas fa-minus-circle ml-3 visibility-hidden"></i>
              </div>
              <div
                class="column is-4 is-flex is-align-items-center is-justify-content-space-around"
              >
                <span class="font-18 visibility-hidden"
                  >{{ this.$t("message.pages.paymentPage.eachPrepText") }} XX,XX
                  €</span
                >
                <span class="font-18 has-text-weight-bold">
                  =
                  {{ (((getTotalPrice * 1.255) + getDesignServiceTotalFee).toFixed(2) - this.voucherDiscount).toFixed(2) }}
                  €</span
                >
              </div>
            </div>
          </div>

          <!-- Start voucher -->
          <div class="column is-10 is-offset-1">
            <div class="columns">
              <div class="column is-4">
                <input
                  class="input pm-input"
                  type="text"
                  placeholder="Voucher"
                  v-model="voucherCode"
                />
                <span class="control is-expanded is-small has-text-danger">
                  {{ voucherCodeError }}
                </span>
              </div>

              <div
                class="column is-2 is-flex is-align-items-center is-justify-content-center"
              >
                <button class="button pm-small-button" @click="addVoucher">
                  {{ this.$t("message.pages.cartPage.voucherButtonText") }}
                </button>
              </div>
            </div>
          </div>
          <!-- End voucher -->

          <!-- Start Contact data -->
          <div class="column is-10 is-offset-1">
            <div class="columns">
              <div class="column mt-5">
                <h4 class="custom-black font-secondary mb-3">
                  {{ this.$t("message.pages.paymentPage.contactHeadingOne") }}
                </h4>
                <p>{{ getContactData().recv_name }}</p>
                <p>{{ getContactData().recv_phone }}</p>
                <p>{{ getContactData().cust_email }}</p>
                <p>{{ getContactData().recv_street }}</p>
                <p>{{ getContactData().recv_zip }}</p>
                <p>{{ getContactData().recv_town }}</p>
                <h4
                  class="custom-black mt-5 mb-3 font-secondary"
                  v-if="this.getContactData().bill_name != ''"
                >
                  {{ this.$t("message.pages.paymentPage.contactHeadingTwo") }}
                </h4>
                <p v-if="this.getContactData().cust_vat_nr != ''">
                  {{ getContactData().bill_name }}
                </p>
                <p v-if="this.getContactData().cust_vat_nr != ''">
                  {{ getContactData().bill_company }}
                </p>
                <p v-if="this.getContactData().cust_vat_nr != ''">
                  {{ getContactData().bill_phone }}
                </p>
                <p v-if="this.getContactData().cust_vat_nr != ''">
                  {{ getContactData().bill_street }}
                </p>
                <p v-if="this.getContactData().cust_vat_nr != ''">
                  {{ getContactData().bill_town }}
                </p>
              </div>
            </div>
          </div>
          <!-- End Contact data -->

          <div class="column is-10 is-offset-1">
            <div class="columns" v-if="this.getContactData().b2b">
              <div class="column mt-5">
                {{ this.$t("message.pages.paymentPage.lahetammeText_1") }}
                <br>
                {{ this.$t("message.pages.paymentPage.lahetammeText_2") }}
              </div>
            </div>
            <div class="columns">
              <div class="column mt-5 is-flex is-justify-content-space-around buttons-wrapper">
                <button class="button pm-button my-5" @click="goBack()">
                  {{ this.$t("message.pages.paymentPage.goBackButtonText") }}
                </button>
                <button
                  class="button pm-button my-5"
                  :enabled="stripe_loaded"
                  @click="goPayByCard()"
                >
                  {{ this.$t("message.pages.paymentPage.payByCardButtonText") }}
                </button>
                <button
                  class="button pm-button my-5"
                  v-if="this.getContactData().b2b"
                  :enabled="stripe_loaded"
                  @click="goPayByBill()"
                >
                  {{ this.$t("message.pages.paymentPage.payByBillButtonText") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <PmFooter />
  </section>
</template>

<script>
import PmFooter from "../components/PmFooter"
import PmNavbar from '../components/PmNavbar'
import { mapGetters, mapMutations, mapActions } from "vuex"
import cartMixin from "@/mixins/cartMixin"

export default {
  name: "PaymentPage",
  components: {
    PmFooter,
    PmNavbar
  },
  data() {
    return {
      stripe_loaded: false,
      voucherCode: "",
      voucherCodeError: "",
      voucherDiscount: "0",
      voucherId: null,
      voucherFixedPriceId: null
    };
  },
  methods: {
    ...mapGetters({
      getContactData: "getContactData",
      getProducts: "getProducts"
    }),
    ...mapMutations({
      setSpinnerState: "setSpinnerState",
      setFixedVoucher: "setFixedVoucher"
    }),
    ...mapActions({
      createStripeSession: "createStripeSession",
      partialUpdateOrder: "partialUpdateOrder",
    }),
    addVoucher() {
      this.voucherCodeError = "";
      if (this.voucherCode.length < 1) {
        this.voucherCodeError = "Lisää alennus koodi";
        return;
      }
      this.setSpinnerState(true);
      this.$store
        .dispatch("addVoucher", { code: this.voucherCode })
        .then((resp) => {
          if (resp.status === 404) {
            this.voucherCodeError = "Lisää oikea alennus koodi";
          } else {
            if (resp.data.amount_off != null) {
              this.voucherDiscount = (resp.data.amount_off / 100).toFixed(2);
              this.voucherId = resp.data.id;
            } else if (resp.data.percent_off != null) {
              this.voucherDiscount = (
                (this.getTotalPrice * 1.255).toFixed(2) *
                1 *
                parseFloat(resp.data.percent_off / 100)
              ).toFixed(2);
              this.voucherId = resp.data.id;
            } else if (resp.data.price && resp.data.product) {
              this.setFixedVoucher({
                product: resp.data.product,
                price: (resp.data.price / 100).toFixed(2) * 1 // backend saves in cents
              })
              this.voucherFixedPriceId = resp.data.id
            }
          }
        })
        .catch((err) => (this.voucherCodeError.value = err))
        .finally(() => {
          this.setSpinnerState(false);
        });
    },
    getProductName(product_id) {
      return this.getProducts().find(p => p.id == product_id).name
    },
    goBack() {
      this.$router.push({
        name: "Cart",
        params: {
          uuid: this.$route.params.uuid,
        },
      });
    },
    goPayByCard() {
      let stripe = Stripe(process.env.VUE_APP_STRIPE_API_KEY); // eslint-disable-line
      this.setSpinnerState(true);
      this.partialUpdateOrder({
        discount_code: this.voucherId,
        fixed_discount_code: this.voucherFixedPriceId
      }).then(() => {
        this.createStripeSession()
          .then(() => {
            return this.$store.state.stripeSessionData.stripe_session_id;
          })
          .then((session_id) => {
            return stripe.redirectToCheckout({ sessionId: session_id });
          })
          .then(function (result) {
            // If `redirectToCheckout` fails due to a browser or network
            // error, you should display the localized error message to your
            // customer using `error.message`.
            if (result.error) {
              alert(result.error.message);
            }
          })
          .catch(function (error) {
            console.error("Error:", error);
          });
      });

    },
    goPayByBill() {
      this.setSpinnerState(true);
      let data = {
        status: "ORDERED",
      };
      if (this.voucherId) {
        data.discount_code = this.voucherId;
      }

      if (this.voucherFixedPriceId) {
        data.fixed_discount_code = this.voucherFixedPriceId
      }

      this.partialUpdateOrder(data).then(() => {
        this.setSpinnerState(false);
        this.$router.push({ name: "thanks" });
      });
    },
  },
  mixins: [cartMixin],
  beforeMount() {
    let stripeScript = document.createElement("script");
    stripeScript.setAttribute("src", "https://js.stripe.com/v3/");
    stripeScript.async = true;
    stripeScript.ref = "stripe";

    stripeScript.addEventListener("load", () => {
      // Create Stripe instance once Stripe.js loads
      this.stripe_loaded = true;
    });

    document.head.appendChild(stripeScript);
  },
  mounted() {},
  computed: {},
};
</script>

<style scoped lang="scss">
@import "../assets/sass/mybulma";
.payment-page {

  .container {
    padding-left: 12px;
    padding-right: 12px;
  }

  .custom-black {
    color: $font-color-primary;
  }

  .white-bg {
    background-color: #ffffff;
  }

  .light-bg {
    background-color: #f0efe4;
  }
  .amount-input {
    width: 66px;
    height: 31px;
    background-color: #dedbd9;
  }
  .border-bottom {
    border-bottom: 1px solid #707070;
  }
  .visibility-hidden {
    visibility: hidden;
  }

  // Remove arrows in number input type
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  .svg-inline--fa {
    cursor: pointer;
  }

  .mandatory-fields-text {
    color: #dedbd9;
  }

  .pm-input {
    border: 1px solid #707070 !important;
    height: 55px;
    font-size: 18px;
    font-family: open-sans, sans-serif;
    font-weight: 400;
    font-style: normal;
    &::placeholder {
      font-family: open-sans, sans-serif;
      font-weight: 400;
      font-style: normal;      
    }
  }
  .buttons-wrapper {
    @media only screen and (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }
  }
}

.text-wrapper {
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
